/* .App {
  display: flex;
} */

.w-sidebar {
  width: calc(1 / 6 * 100%);
}

.ml-sidebar {
  margin-left: calc(1 / 6 * 100%); /* width of Sidebar */
}
.btn {
  padding: 16px;
  background-color: aqua;
}

button.ant-btn-primary {
  background-color: #1677ff;
}
