.widget {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.bullet {
  list-style-type: none;
}

.label {
  color: rgb(87, 79, 79);
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}

.number {
  font-size: x-large;
  color: black;
  font-weight: bold;
  text-align: left;
}

.percentage {
  color: green;
  font-size: small;
}
